import settings from './settings';
import transactions from './transactions';
import dashboard from './dashboard';
import production from './production';
import { IconBuildingFactory, IconDashboard, IconExchange, IconTools, IconPackages, IconReportAnalytics } from '@tabler/icons';
import storage from './storage';
import reports from './reports';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [
        {
            ...dashboard,
            icon: IconDashboard
        },
        {
            ...transactions,
            icon: IconExchange
        },
        {
            ...production,
            icon: IconBuildingFactory
        },
        {
            ...storage,
            icon: IconPackages
        },
        {
            ...reports,
            icon: IconReportAnalytics
        },
        {
            ...settings,
            icon: IconTools
        }
    ]
};

export default menuItems;
