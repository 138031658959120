// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconReportAnalytics, IconCheckupList, IconBadge4k, IconCalendarEvent } from '@tabler/icons';

// constant
const icons = {
    IconCheckupList,
    IconReportAnalytics,
    IconBadge4k,
    IconCalendarEvent
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const reports = {
    id: 'reports',
    title: <FormattedMessage id="reports" />,
    icon: icons.IconHelp,
    type: 'group',
    children: [
        {
            id: 'reports/monthly',
            title: <FormattedMessage id="monthly" />,
            type: 'item',
            url: '/reports/monthly',
            icon: icons.IconCalendarEvent,
            breadcrumbs: false
        },
        {
            id: 'reports/inventory',
            title: <FormattedMessage id="inventory" />,
            type: 'item',
            url: '/reports/inventory',
            icon: icons.IconCheckupList,
            breadcrumbs: false
        },
        {
            id: 'reports/closing-inventory',
            title: 'Closing Inventory',
            type: 'item',
            url: '/reports/closing-inventory',
            icon: icons.IconReportAnalytics,
            breadcrumbs: false
        }
    ]
};

export default reports;
