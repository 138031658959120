import { lazy, Suspense } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import Loader from 'ui-component/Loader';
import AuthorizationGuard from 'utils/route-guard/AuthorizationGuard';
import TokenGuard from 'utils/route-guard/TokenGuard';

const ErrorPage = Loadable(lazy(() => import('views/Error')));

// sample page routing
// const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const SamplePage = Loadable(lazy(() => import('views/dashboard/index')));
const AppUserList = Loadable(lazy(() => import('views/settings/users/UserList')));
const AppOrganismList = Loadable(lazy(() => import('views/settings/organisms/OrganismsList')));
const AppCompanyOrderList = Loadable(lazy(() => import('views/settings/company/CompanyList')));
const AppInstallationList = Loadable(lazy(() => import('views/settings/installation/InstallationList')));
const AppUserAccountProfile3 = Loadable(lazy(() => import('views/settings/users/account-profile/Profile')));
const AppLicencesList = Loadable(lazy(() => import('views/settings/licences/LicencesList')));
const AppStrainsList = Loadable(lazy(() => import('views/settings/strains/StrainsList')));
const AppImportationList = Loadable(lazy(() => import('views/transactions/importation/ImportationList')));
const AppExpeditionList = Loadable(lazy(() => import('views/transactions/expedition/ExpeditionList')));
const AppDestructionList = Loadable(lazy(() => import('views/transactions/destruction/DestructionList')));
const AppDriedCannabis = Loadable(lazy(() => import('views/production/driedcannabis/DriedCannabis')));
const AppFreshCannabis = Loadable(lazy(() => import('views/production/freshcannabis/FreshCannabis')));
const AppGraines = Loadable(lazy(() => import('views/production/graines/Graines')));
const AppNonFlower = Loadable(lazy(() => import('views/production/nonflower/NonFlower')));
const AppVegetative = Loadable(lazy(() => import('views/production/vegetative/Vegetative')));
const AppWhlolePlant = Loadable(lazy(() => import('views/production/wholeplant/WholePlant')));
const AppBatchDetails = Loadable(lazy(() => import('views/production/ViewBatch')));
const AppVracDetails = Loadable(lazy(() => import('views/storage/vrac/ViewDetailVrac')));
const AppRetentions = Loadable(lazy(() => import('views/storage/retentions/ViewAllRetentions')));
const AppStorageVrac = Loadable(lazy(() => import('views/storage/vrac/ViewAllVrac')));
const AppReportsPage = Loadable(lazy(() => import('views/reports/monthly/ReportsPage')));
const AppInventoryReportsList = Loadable(lazy(() => import('views/reports/inventory/InventoryReportsList')));
const AppClosingInventoryReportsList = Loadable(lazy(() => import('views/reports/closinginventory/ClosingInventoryReportsList')));
const AppAvailableLots = Loadable(lazy(() => import('views/production/availablelots/AvailableLots')));
const AppContainersList = Loadable(lazy(() => import('views/settings/containers/ContainersList')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard/default',
            element: (
                <Suspense fallback={<Loader />}>
                    <AuthorizationGuard allowedIds={[1, 2, 3, 4]}>
                        <SamplePage />
                    </AuthorizationGuard>
                </Suspense>
            )
        },
        /* {
            path: '/pulse',
            element: (
                <a href="http://localhost:8000/pulse" target="_blank" rel="noopener noreferrer">
                    Access Pulse Page
                </a>
            )
        }, */
        {
            path: '/',
            element: (
                <Suspense fallback={<Loader />}>
                    <AuthorizationGuard allowedIds={[1, 2, 3, 4]}>
                        <SamplePage />
                    </AuthorizationGuard>
                </Suspense>
            )
        },
        {
            path: '/settings/installations',
            element: (
                <Suspense fallback={<Loader />}>
                    <AuthorizationGuard allowedIds={[1, 2, 3, 4]}>
                        <AppInstallationList />
                    </AuthorizationGuard>
                </Suspense>
            )
        },
        {
            path: '/settings/containers',
            element: (
                <Suspense fallback={<Loader />}>
                    <AuthorizationGuard allowedIds={[1, 2, 3, 4]}>
                        <AppContainersList />
                    </AuthorizationGuard>
                </Suspense>
            )
        },
        {
            path: '/settings/users',
            element: (
                <Suspense fallback={<Loader visible />}>
                    <AuthorizationGuard allowedIds={[1, 2, 3, 4]}>
                        <AppUserList />
                    </AuthorizationGuard>
                </Suspense>
            )
        },
        {
            path: '/settings/companies',
            element: (
                <Suspense fallback={<Loader visible />}>
                    <AuthorizationGuard allowedIds={[1]}>
                        <AppCompanyOrderList />
                    </AuthorizationGuard>
                </Suspense>
            )
        },
        {
            path: '/settings/licences',
            element: (
                <Suspense fallback={<Loader visible />}>
                    <AuthorizationGuard allowedIds={[1, 2, 3, 4]}>
                        <AppLicencesList />
                    </AuthorizationGuard>
                </Suspense>
            )
        },
        {
            path: '/settings/strains',
            element: (
                <Suspense fallback={<Loader visible />}>
                    <AuthorizationGuard allowedIds={[1, 2, 3, 4]}>
                        <AppStrainsList />
                    </AuthorizationGuard>
                </Suspense>
            )
        },
        {
            path: '/settings/organisms',
            element: (
                <Suspense fallback={<Loader visible />}>
                    <AuthorizationGuard allowedIds={[1, 2, 3, 4]}>
                        <AppOrganismList />
                    </AuthorizationGuard>
                </Suspense>
            )
        },
        {
            path: '/reports/monthly',
            element: (
                <Suspense fallback={<Loader visible />}>
                    <AuthorizationGuard allowedIds={[1, 2, 3, 4]}>
                        <AppReportsPage />
                    </AuthorizationGuard>
                </Suspense>
            )
        },
        {
            path: '/reports/inventory',
            element: (
                <Suspense fallback={<Loader visible />}>
                    <AuthorizationGuard allowedIds={[1, 2, 3, 4]}>
                        <AppInventoryReportsList />
                    </AuthorizationGuard>
                </Suspense>
            )
        },
        {
            path: '/reports/closing-inventory',
            element: (
                <Suspense fallback={<Loader visible />}>
                    <AuthorizationGuard allowedIds={[1, 2, 3, 4]}>
                        <AppClosingInventoryReportsList />
                    </AuthorizationGuard>
                </Suspense>
            )
        },
        {
            path: '/user/profile',
            element: (
                <Suspense fallback={<Loader visible />}>
                    <AuthorizationGuard allowedIds={[1, 2, 3, 4]}>
                        <AppUserAccountProfile3 />
                    </AuthorizationGuard>
                </Suspense>
            )
        },
        {
            path: '/production/graines',
            element: (
                <Suspense fallback={<Loader visible />}>
                    <AuthorizationGuard allowedIds={[1, 2, 3, 4]}>
                        <AppGraines />
                    </AuthorizationGuard>
                </Suspense>
            )
        },
        {
            path: '/production/cannabis-séché',
            element: (
                <Suspense fallback={<Loader visible />}>
                    <AuthorizationGuard allowedIds={[1, 2, 3, 4]}>
                        <AppDriedCannabis />
                    </AuthorizationGuard>
                </Suspense>
            )
        },
        {
            path: '/production/cannabis-frais',
            element: (
                <Suspense fallback={<Loader visible />}>
                    <AuthorizationGuard allowedIds={[1, 2, 3, 4]}>
                        <AppFreshCannabis />
                    </AuthorizationGuard>
                </Suspense>
            )
        },
        {
            path: '/production/plantes-végétatives',
            element: (
                <Suspense fallback={<Loader visible />}>
                    <AuthorizationGuard allowedIds={[1, 2, 3, 4]}>
                        <AppVegetative />
                    </AuthorizationGuard>
                </Suspense>
            )
        },
        {
            path: '/production/non-florifière',
            element: (
                <Suspense fallback={<Loader visible />}>
                    <AuthorizationGuard allowedIds={[1, 2, 3, 4]}>
                        <AppNonFlower />
                    </AuthorizationGuard>
                </Suspense>
            )
        },
        {
            path: '/production/plantes-entières',
            element: (
                <Suspense fallback={<Loader visible />}>
                    <AuthorizationGuard allowedIds={[1, 2, 3, 4]}>
                        <AppWhlolePlant />
                    </AuthorizationGuard>
                </Suspense>
            )
        },
        {
            path: '/production/batch-details/:batchId',
            element: (
                <Suspense fallback={<Loader visible />}>
                    <AuthorizationGuard allowedIds={[1, 2, 3, 4]}>
                        <AppBatchDetails />
                    </AuthorizationGuard>
                </Suspense>
            )
        },
        {
            path: '/production/plantes-entières',
            element: (
                <Suspense fallback={<Loader visible />}>
                    <AuthorizationGuard allowedIds={[1, 2, 3, 4]}>
                        <AppWhlolePlant />
                    </AuthorizationGuard>
                </Suspense>
            )
        },
        {
            path: '/production/lots-disponibles',
            element: (
                <Suspense fallback={<Loader visible />}>
                    <AuthorizationGuard allowedIds={[1, 2, 3, 4]}>
                        <AppAvailableLots />
                    </AuthorizationGuard>
                </Suspense>
            )
        },
        {
            path: 'transactions/importation',
            element: (
                <Suspense fallback={<Loader visible />}>
                    <AuthorizationGuard allowedIds={[1, 2, 3, 4]}>
                        <AppImportationList />
                    </AuthorizationGuard>
                </Suspense>
            )
        },
        {
            path: 'transactions/expedition',
            element: (
                <Suspense fallback={<Loader visible />}>
                    <AuthorizationGuard allowedIds={[1, 2, 3, 4]}>
                        <AppExpeditionList />
                    </AuthorizationGuard>
                </Suspense>
            )
        },
        {
            path: 'transactions/destruction',
            element: (
                <Suspense fallback={<Loader visible />}>
                    <AuthorizationGuard allowedIds={[1, 2, 3, 4]}>
                        <AppDestructionList />
                    </AuthorizationGuard>
                </Suspense>
            )
        },
        {
            path: '/storage/batch/:batchId/:productId',
            element: (
                <Suspense fallback={<Loader visible />}>
                    <AuthorizationGuard allowedIds={[1, 2, 3, 4]}>
                        <AppVracDetails />
                    </AuthorizationGuard>
                </Suspense>
            )
        },
        {
            path: '/storage/sublot/:sublotId',
            element: (
                <Suspense fallback={<Loader visible />}>
                    <AuthorizationGuard allowedIds={[1, 2, 3, 4]}>
                        <AppVracDetails />
                    </AuthorizationGuard>
                </Suspense>
            )
        },
        {
            path: '/storage/vrac',
            element: (
                <Suspense fallback={<Loader visible />}>
                    <AuthorizationGuard allowedIds={[1, 2, 3, 4]}>
                        <AppStorageVrac />
                    </AuthorizationGuard>
                </Suspense>
            )
        },
        {
            path: '/storage/retentions',
            element: (
                <Suspense fallback={<Loader visible />}>
                    <AuthorizationGuard allowedIds={[1, 2, 3, 4]}>
                        <AppRetentions />
                    </AuthorizationGuard>
                </Suspense>
            )
        },
        {
            path: '*',
            element: <ErrorPage />
        },
        {
            path: '/404',
            element: <ErrorPage />
        }
    ]
};

export default MainRoutes;
