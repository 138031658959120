// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    ctls: '',
    reports: [],
    inventoryReports: [],
    submittedReports: [],
    closingInventoryReports: []
};

const slice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        getAllReportsSuccess(state, action) {
            state.reports = action.payload;
        },
        getSingleCtlsSuccess(state, action) {
            state.ctls = action.payload;
        },
        getAllInventoryReportsSuccess(state, action) {
            state.inventoryReports = action.payload;
        },
        getAllSubmittedReportsSuccess(state, action) {
            state.submittedReports = action.payload;
        },
        getAllClosingInventoryReportsSuccess(state, action) {
            state.closingInventoryReports = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export const reportsActions = slice.actions;

// ----------------------------------------------------------------------
export function getAllReports() {
    return async () => {
        try {
            const response = await axios.get('/api/reports');
            dispatch(slice.actions.getAllReportsSuccess(response.data.reports));
        } catch (error) {
            console.log('ERROR FETCHING', error);
        }
    };
}
export function getSingleCtls(filename) {
    return async (dispatch) => {
        try {
            const pdfUrl = `${window.location.origin}/api/reports/ctls/${filename}`;
            dispatch(slice.actions.getSingleCtlsSuccess(pdfUrl));
        } catch (error) {
            console.log('ERROR FETCHING', error);
            dispatch(slice.actions.getSingleCtlsFailure());
        }
    };
}
export function getAllInventoryReports() {
    return async () => {
        try {
            const response = await axios.get('/api/inventory-reports');
            dispatch(slice.actions.getAllInventoryReportsSuccess(response.data.snapshots));
        } catch (error) {
            console.log('ERROR FETCHING', error);
        }
    };
}
export function getAllSubmittedReports() {
    return async () => {
        try {
            const response = await axios.get('/api/submitted-reports');
            dispatch(slice.actions.getAllSubmittedReportsSuccess(response.data.reports));
        } catch (error) {
            console.log('ERROR FETCHING', error);
        }
    };
}
export function getAllClosingInventoryReports() {
    return async () => {
        try {
            const response = await axios.get('/api/inventory-reports-automatically-generated');
            dispatch(slice.actions.getAllClosingInventoryReportsSuccess(response.data.snapshots));
        } catch (error) {
            console.log('ERROR FETCHING', error);
        }
    };
}
