// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconHeartHandshake,
    IconBrandChrome,
    IconBuildingSkyscraper,
    IconTool,
    IconUsers,
    IconCertificate,
    IconCannabis,
    IconReportAnalytics,
    IconBucket
} from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome,
    IconTool,
    IconUsers,
    IconBuildingSkyscraper,
    IconHeartHandshake,
    IconCertificate,
    IconCannabis,
    IconReportAnalytics,
    IconBucket
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const settings = {
    id: 'sample-docs-roadmap',
    title: <FormattedMessage id="settings" />,
    icon: icons.IconHelp,
    type: 'group',
    children: [
        {
            id: 'settings/companies',
            title: <FormattedMessage id="companies" />,
            type: 'item',
            url: '/settings/companies',
            icon: icons.IconBuildingSkyscraper,
            breadcrumbs: false
        },
        {
            id: 'settings/installations',
            title: <FormattedMessage id="installations" />,
            type: 'item',
            url: '/settings/installations',
            icon: icons.IconTool,
            breadcrumbs: false
        },
        {
            id: 'settings/containers',
            title: <FormattedMessage id="containers" />,
            type: 'item',
            url: '/settings/containers',
            icon: icons.IconBucket,
            breadcrumbs: false
        },
        {
            id: 'settings/users',
            title: <FormattedMessage id="users" />,
            type: 'item',
            url: '/settings/users',
            icon: icons.IconUsers,
            breadcrumbs: false
        },
        {
            id: 'settings/organisms',
            title: <FormattedMessage id="organisms" />,
            type: 'item',
            url: '/settings/organisms',
            icon: icons.IconHeartHandshake,
            breadcrumbs: false
        },
        {
            id: 'settings/licences',
            title: <FormattedMessage id="licences" />,
            type: 'item',
            url: '/settings/licences',
            icon: icons.IconCertificate,
            breadcrumbs: false
        },
        {
            id: 'settings/strains',
            title: <FormattedMessage id="strains" />,
            type: 'item',
            url: '/settings/strains',
            icon: icons.IconCannabis,
            breadcrumbs: false
        }
    ]
};

export default settings;
